import _ from 'lodash'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatShort } from 'utils/formatShort'
import { CurrencyTooltip, NumericTooltip } from './Tooltip'

const formatTick = (value, txCount, volume, showUSDVolume) => {
  if (showUSDVolume) {
    return `$${formatShort.format(value)}`
  }

  return formatShort.format(value)
}

export const OverviewChart = ({ data, showAggregated, currency, height = 480 }) => {
  return (
    <ResponsiveContainer width={'100%'} height={height}>
      <BarChart width={0} height={480} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis hide dataKey="date" stroke="#374151" />
        <YAxis
          interval="preserveStart"
          width={40}
          stroke="#374151"
          tickCount={10}
          tickFormatter={currency ? (value) => `${_.round(value / 1000000, 0)}M` : (value) => `${value}`}
        />
        <Tooltip wrapperClassName="rounded-lg shadow-lg" content={currency ? CurrencyTooltip : NumericTooltip} />
        {showAggregated ? (
          <Bar isAnimationActive={false} dataKey="total" stackId="a" fill="#6a30b4" />
        ) : (
          <>
            <Bar isAnimationActive={false} dataKey="ethereum" stackId="a" fill="#637eea" />
            <Bar isAnimationActive={false} dataKey="avalanche" stackId="a" fill="#e74242" />
            <Bar isAnimationActive={false} dataKey="polygon" stackId="a" fill="#7b3fe4" />
            <Bar isAnimationActive={false} dataKey="bsc" stackId="a" fill="#efb90b" />
            <Bar isAnimationActive={false} dataKey="arbitrum" stackId="a" fill="#2d374b" />
            <Bar isAnimationActive={false} dataKey="fantom" stackId="a" fill="#1969ff" />
            <Bar isAnimationActive={false} dataKey="harmony" stackId="a" fill="#39cdd8" />
            <Bar isAnimationActive={false} dataKey="optimism" stackId="a" fill="#fe0621" />
            <Bar isAnimationActive={false} dataKey="moonriver" stackId="a" fill="#f2b707" />
            <Bar isAnimationActive={false} dataKey="boba" stackId="a" fill="#cbff00" />
            <Bar isAnimationActive={false} dataKey="aurora" stackId="a" fill="#78d64b" />
            <Bar isAnimationActive={false} dataKey="moonbeam" stackId="a" fill="#20223c" />
          </>
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}

export const FlowChart = ({ data, volume, txCount, showUSDVolume, height = 480 }) => {
  return (
    <ResponsiveContainer width={'100%'} height={height}>
      <BarChart width={0} height={480} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis hide dataKey="date" stroke="#374151" />
        <YAxis
          interval="preserveStart"
          width={32}
          stroke="#374151"
          tickFormatter={(value) => formatTick(value, txCount, volume, showUSDVolume)}
        />
        <Tooltip
          wrapperClassName="rounded-lg shadow-lg"
          content={showUSDVolume === true ? CurrencyTooltip : NumericTooltip}
        />
        {txCount && <Bar isAnimationActive={false} dataKey="txCount" stackId="a" fill="#434971" />}
        {volume && (
          <Bar
            isAnimationActive={false}
            dataKey={showUSDVolume ? 'usdVolume' : 'coinVolume'}
            stackId="a"
            fill="#e74242"
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}
