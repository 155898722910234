import { Card } from 'components/Sections'
import _ from 'lodash'
import useSWR from 'swr'
import { fetcher } from 'utils/fetcher'

export const StatisticsSection = () => {
  const { data: marketCapData } = useSWR('https://synapse.dorime.org/api/v1/mcap/', fetcher)
  const { data: volumeData } = useSWR('https://synapse.dorime.org/api/v1/analytics/volume/total', fetcher)
  const { data: tvlData } = useSWR('https://api.llama.fi/protocol/synapse', fetcher)

  const synMarketCap = marketCapData?.market_cap

  let totalAmt
  if (volumeData?.totals) {
    totalAmt = _.sum(_.values(volumeData?.totals))
  }

  let currentTvl
  if (tvlData?.currentChainTvls) {
    const currentTvlAmt = _.sum(_.values(tvlData?.currentChainTvls))
    const currentTvlAmtInBil = currentTvlAmt / 1000000000
    const currentTvlAmtInMil = currentTvlAmt / 1000000
    if (currentTvlAmtInBil >= 1) {
      currentTvl = `${_.round(currentTvlAmtInBil, 2)}B`
    } else {
      currentTvl = `${_.round(currentTvlAmtInMil, 2)}M`
    }
  } else {
    currentTvl = '-'
  }

  return (
    <div className="grid gap-4 mb-8 text-gray-200 md:grid-cols-2 lg:grid-cols-4">
      <Card>
        <h2 className="mb-2 text-xl font-bold text-gray-400">Market Cap</h2>
        <p className="text-4xl font-bold">{`$${synMarketCap ? _.round(synMarketCap / 1000000) : '0'}M`}</p>
      </Card>
      <Card>
        <h2 className="mb-2 text-xl font-bold text-gray-400">Total Value Locked</h2>
        <p className="text-4xl font-bold">{`$${currentTvl}`}</p>
      </Card>
      <Card>
        <h2 className="mb-2 text-xl font-bold text-gray-400">Total Bridged Volume</h2>
        <p className="text-4xl font-bold">{`$${totalAmt ? _.round(totalAmt / 1000000000, 2) : '0'}B`}</p>
      </Card>
      <Card>
        <h2 className="mb-2 text-xl font-bold text-gray-400">Current Price</h2>
        <p className="text-4xl font-bold">{`$${synMarketCap ? (synMarketCap / 169429601).toFixed(2) : '0'}`}</p>
      </Card>
    </div>
  )
}
