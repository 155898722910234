import avaxLogo from 'assets/icons/avalanche.svg'
import avwethLogo from 'assets/icons/avweth.svg'
import busdLogo from 'assets/icons/busd.svg'
import daiLogo from 'assets/icons/dai.png'
import ethLogo from 'assets/icons/eth.svg'
import fraxLogo from 'assets/icons/frax.svg'
import mimLogo from 'assets/icons/mim.svg'
import movrLogo from 'assets/icons/moonriver.jpeg'
import nethLogo from 'assets/icons/neth.svg'
import nusdLogo from 'assets/icons/nusd.svg'
import synapseLogo from 'assets/icons/synapse.svg'
import usdcLogo from 'assets/icons/usdc.svg'
import usdtLogo from 'assets/icons/usdt.svg'
import ustLogo from 'assets/icons/ust.png'
import wethLogo from 'assets/icons/weth.svg'
import _ from 'lodash'
import { CHAIN_ID } from './chains'
import { DOG, GMX, GOHM, HIGHSTREET, JUMP, NFD, SOLAR } from './mintable'
import { Token } from './Token'

export const BUSD = new Token({
  addresses: {
    [CHAIN_ID.BSC]: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  decimals: 18,
  symbol: 'BUSD',
  name: 'Binance USD',
  logo: busdLogo,
  description: `
    BUSD is a stablecoin that is pegged to the US dollar and
    backed/issued by Binance
  `,
  swapableType: 'USD',
})

export const USDC = new Token({
  addresses: {
    [CHAIN_ID.BSC]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    [CHAIN_ID.ETH]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    [CHAIN_ID.POLYGON]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    [CHAIN_ID.FANTOM]: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    [CHAIN_ID.AVALANCHE]: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    [CHAIN_ID.ARBITRUM]: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    [CHAIN_ID.HARMONY]: '0x985458e523db3d53125813ed68c274899e9dfab4',
    [CHAIN_ID.BOBA]: '0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc',
    [CHAIN_ID.AURORA]: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
  },
  decimals: {
    [CHAIN_ID.BSC]: 18,
    [CHAIN_ID.ETH]: 6,
    [CHAIN_ID.POLYGON]: 6,
    [CHAIN_ID.FANTOM]: 6,
    [CHAIN_ID.AVALANCHE]: 6,
    [CHAIN_ID.ARBITRUM]: 6,
    [CHAIN_ID.HARMONY]: 6,
    [CHAIN_ID.BOBA]: 6,
    [CHAIN_ID.AURORA]: 6,
  },
  symbol: 'USDC',
  name: 'USD Circle',
  logo: usdcLogo,
  description: `
    USD Coin (known by its ticker USDC) is a stablecoin that is pegged to the
    U.S. dollar on a 1:1 basis. Every unit of this cryptocurrency in circulation
    is backed up by $1 that is held in reserve
    `,
  swapableType: 'USD',
})

export const USDT = new Token({
  addresses: {
    [CHAIN_ID.BSC]: '0x55d398326f99059ff775485246999027b3197955',
    [CHAIN_ID.ETH]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    [CHAIN_ID.POLYGON]: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    [CHAIN_ID.AVALANCHE]: '0xc7198437980c041c805a1edcba50c1ce5db95118',
    [CHAIN_ID.HARDHAT]: '0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE',
    [CHAIN_ID.ARBITRUM]: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    [CHAIN_ID.FANTOM]: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    [CHAIN_ID.HARMONY]: '0x3c2b8be99c50593081eaa2a724f0b8285f5aba8f',
    [CHAIN_ID.BOBA]: '0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d',
    [CHAIN_ID.AURORA]: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
  },
  decimals: {
    [CHAIN_ID.BSC]: 18,
    [CHAIN_ID.ETH]: 6,
    [CHAIN_ID.POLYGON]: 6,
    [CHAIN_ID.AVALANCHE]: 6,
    [CHAIN_ID.ARBITRUM]: 6,
    [CHAIN_ID.FANTOM]: 6,
    [CHAIN_ID.HARMONY]: 6,
    [CHAIN_ID.BOBA]: 6,
    [CHAIN_ID.AURORA]: 6,
  },
  symbol: 'USDT',
  name: 'USD Tether',
  logo: usdtLogo,
  description: `
    USDT mirrors the price of the U.S. dollar, issued by a Hong Kong-based company Tether.
    The token’s peg to the USD is achieved via maintaining a sum of dollars in reserves equal
    to the number of USDT in circulation.
    `,
  swapableType: 'USD',
})

export const DAI = new Token({
  addresses: {
    [CHAIN_ID.BSC]: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    [CHAIN_ID.ETH]: '0x6b175474e89094c44da98b954eedeac495271d0f',
    [CHAIN_ID.POLYGON]: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    [CHAIN_ID.AVALANCHE]: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    [CHAIN_ID.ARBITRUM]: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    [CHAIN_ID.HARMONY]: '0xef977d2f931c1978db5f6747666fa1eacb0d0339',
    [CHAIN_ID.BOBA]: '0xf74195Bb8a5cf652411867c5C2C5b8C2a402be35',
  },
  decimals: 18,
  symbol: 'DAI',
  name: 'Dai',
  logo: daiLogo,
  swapableType: 'USD',
})

export const UST = new Token({
  addresses: {
    [CHAIN_ID.BSC]: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
  },
  decimals: 18,
  symbol: 'UST',
  name: 'TerraUSD',
  logo: ustLogo,
  swapableType: 'USD',
})

export const MIM = new Token({
  addresses: {
    [CHAIN_ID.FANTOM]: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    [CHAIN_ID.ARBITRUM]: '0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a',
  },
  decimals: 18,
  symbol: 'MIM',
  name: 'Magic Internet Money',
  logo: mimLogo,
  swapableType: 'USD',
})

export const WETH = new Token({
  addresses: {
    [CHAIN_ID.ETH]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    [CHAIN_ID.ARBITRUM]: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    [CHAIN_ID.BOBA]: '0xd203De32170130082896b4111eDF825a4774c18E',
    [CHAIN_ID.OPTIMISM]: '0x121ab82b49B2BC4c7901CA46B8277962b4350204',
    // [CHAIN_ID.AVALANCHE]: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab'
  },
  decimals: 18,
  symbol: 'WETH', // SHOULD BE WETH
  name: 'Wrapped ETH',
  logo: wethLogo,
  description: 'ERC-20 Wrapped form of ETH',
  swapableType: 'ETH',
})

export const WETHE = new Token({
  addresses: {
    [CHAIN_ID.AVALANCHE]: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
  },
  decimals: 18,
  symbol: 'WETH.e', // SHOULD BE WETH
  name: 'Wrapped ETH',
  logo: wethLogo,
  description: 'ERC-20 Wrapped form of ETH',
  swapableType: 'ETH',
})

/**
 * WETH on Moonbeam is nETH on moonbeam.
 * is this stupid & annoying - yes
 */
export const WETHBEAM = new Token({
  addresses: {
    [CHAIN_ID.MOONBEAM]: '0x3192Ae73315c3634Ffa217f71CF6CBc30FeE349A',
  },
  decimals: 18,
  symbol: 'WETH ',
  name: 'Wrapped ETH',
  logo: wethLogo,
  description: 'ERC-20 Wrapped form of ETH on Moonbeam',
  swapableType: 'ETH',
})

export const AVWETH = new Token({
  addresses: {
    [CHAIN_ID.AVALANCHE]: '0x53f7c5869a859f0aec3d334ee8b4cf01e3492f21',
  },
  decimals: 18,
  symbol: 'AVWETH', // AVALANCHE AAVE WETH
  name: 'Aave Wrapped ETH',
  logo: avwethLogo,
  description: 'Aave Wrapped form of ETH',
  swapableType: 'ETH',
})

export const ONEETH = new Token({
  addresses: {
    [CHAIN_ID.HARMONY]: '0x6983d1e6def3690c4d616b13597a09e6193ea013',
  },
  decimals: 18,
  symbol: '1ETH', // SHOULD BE WETH
  name: 'Harmony ETH',
  logo: wethLogo,
  description: 'Harmony ERC-20 Wrapped form of ETH',
  swapableType: 'ETH',
})

export const SYN = new Token({
  addresses: {
    [CHAIN_ID.ETH]: '0x0f2d719407fdbeff09d87557abb7232601fd9f29',
    [CHAIN_ID.BSC]: '0xa4080f1778e69467e905b8d6f72f6e441f9e9484',
    [CHAIN_ID.POLYGON]: '0xf8f9efc0db77d8881500bb06ff5d6abc3070e695',
    [CHAIN_ID.FANTOM]: '0xE55e19Fb4F2D85af758950957714292DAC1e25B2', // yes this is same as avax swap addr, no its not error
    [CHAIN_ID.ARBITRUM]: '0x080f6aed32fc474dd5717105dba5ea57268f46eb',
    [CHAIN_ID.AVALANCHE]: '0x1f1E7c893855525b303f99bDF5c3c05Be09ca251',
    [CHAIN_ID.HARMONY]: '0xE55e19Fb4F2D85af758950957714292DAC1e25B2',
    [CHAIN_ID.BOBA]: '0xb554A55358fF0382Fb21F0a478C3546d1106Be8c',
    [CHAIN_ID.MOONRIVER]: '0xd80d8688b02B3FD3afb81cDb124F188BB5aD0445',
    [CHAIN_ID.MOONBEAM]: '0xF44938b0125A6662f9536281aD2CD6c499F22004',
    [CHAIN_ID.OPTIMISM]: '0x5A5fFf6F753d7C11A56A52FE47a177a87e431655',
    [CHAIN_ID.AURORA]: '0xd80d8688b02B3FD3afb81cDb124F188BB5aD0445',
  },
  decimals: 18,
  symbol: 'SYN',
  name: 'Synapse',
  logo: synapseLogo,
  description: 'SYN is the base token behind synapse',
  swapableType: 'SYN',
})

export const FRAX = new Token({
  addresses: {
    [CHAIN_ID.ETH]: '0x853d955acef822db058eb8505911ed77f175b99e',
    [CHAIN_ID.MOONRIVER]: '0x1a93b23281cc1cde4c4741353f3064709a16197d',
    [CHAIN_ID.MOONBEAM]: '',
    [CHAIN_ID.HARMONY]: '0xFa7191D292d5633f702B0bd7E3E3BcCC0e633200',
  },
  decimals: 18,
  symbol: 'FRAX',
  name: 'Frax',
  logo: fraxLogo,
  description: 'Frax',
  swapableType: 'FRAX',
})

export const SYN_FRAX = new Token({
  addresses: {
    [CHAIN_ID.MOONRIVER]: '0xE96AC70907ffF3Efee79f502C985A7A21Bce407d',
    [CHAIN_ID.MOONBEAM]: '0xDd47A348AB60c61Ad6B60cA8C31ea5e00eBfAB4F',
    [CHAIN_ID.HARMONY]: '0x1852F70512298d56e9c8FDd905e02581E04ddb2a',
  },
  decimals: 18,
  symbol: 'synFRAX',
  name: 'Synapse Frax',
  logo: synapseLogo,
  description: 'Frax',
  // swapableType: "SYN",
})

/**
 * nUSD is the token involved in the bridge. it is backed by pixie dust...
 */
export const NUSD = new Token({
  addresses: {
    [CHAIN_ID.BSC]: '0x23b891e5c62e0955ae2bd185990103928ab817b3',
    [CHAIN_ID.ETH]: '0x1B84765dE8B7566e4cEAF4D0fD3c5aF52D3DdE4F',
    [CHAIN_ID.POLYGON]: '0xb6c473756050de474286bed418b77aeac39b02af',
    [CHAIN_ID.FANTOM]: '0xED2a7edd7413021d440b09D654f3b87712abAB66',
    [CHAIN_ID.AVALANCHE]: '0xCFc37A6AB183dd4aED08C204D1c2773c0b1BDf46',
    [CHAIN_ID.ARBITRUM]: '0x2913E812Cf0dcCA30FB28E6Cac3d2DCFF4497688',
    [CHAIN_ID.HARMONY]: '0xED2a7edd7413021d440b09D654f3b87712abAB66',
    [CHAIN_ID.BOBA]: '0x6B4712AE9797C199edd44F897cA09BC57628a1CF',
    [CHAIN_ID.AURORA]: '0x07379565cD8B0CaE7c60Dc78e7f601b34AF2A21c',
  },
  decimals: 18,
  symbol: 'nUSD',
  name: 'Synapse nUSD',
  logo: nusdLogo,
  description: 'nUSD',
  swapableType: 'USD',
})

/**
 * nETH is the token involved in the bridge. it is backed by internet monies...
 */
export const NETH = new Token({
  addresses: {
    [CHAIN_ID.ARBITRUM]: '0x3ea9B0ab55F34Fb188824Ee288CeaEfC63cf908e',
    [CHAIN_ID.BOBA]: '0x96419929d7949D6A801A6909c145C8EEf6A40431',
    [CHAIN_ID.OPTIMISM]: '0x809DC529f07651bD43A172e8dB6f4a7a0d771036',
    [CHAIN_ID.AVALANCHE]: '0x19E1ae0eE35c0404f835521146206595d37981ae',
    [CHAIN_ID.HARMONY]: '0x0b5740c6b4a97f90eF2F0220651Cca420B868FfB',
    [CHAIN_ID.MOONBEAM]: '0x3192Ae73315c3634Ffa217f71CF6CBc30FeE349A', // THIS OVERLAPS WITH WETHBEAM
  },
  decimals: 18,
  symbol: 'nETH',
  name: 'Synapse nETH',
  logo: nethLogo,
  description: 'nETH',
  swapableType: 'ETH',
})

export const ETH = new Token({
  addresses: {
    [CHAIN_ID.ETH]: '',
    [CHAIN_ID.BOBA]: '',
    [CHAIN_ID.ARBITRUM]: '',
    [CHAIN_ID.OPTIMISM]: '',
  },
  decimals: 18,
  symbol: 'ETH',
  name: 'Ethereum',
  logo: ethLogo,
  description: 'ETH',
  isNative: true,
  swapableType: 'ETH',
})

export const MOVR = new Token({
  addresses: {
    [CHAIN_ID.MOONRIVER]: '',
  },
  decimals: 18,
  symbol: 'MOVR',
  name: 'MOVR',
  logo: movrLogo,
  description: 'Moonriver',
  isNative: true,
  swapableType: 'MOVR',
})

export const AVAX = new Token({
  addresses: {
    [CHAIN_ID.AVALANCHE]: '',
  },
  decimals: 18,
  symbol: 'AVAX',
  name: 'AVAX',
  logo: avaxLogo,
  description: 'AVAX',
  isNative: true,
  swapableType: 'AVAX',
})

export const WMOVR = new Token({
  addresses: {
    [CHAIN_ID.MOONBEAM]: '0x1d4C2a246311bB9f827F4C768e277FF5787B7D7E',
    [CHAIN_ID.MOONRIVER]: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
  },
  decimals: 18,
  symbol: 'MOVR', // SHOULD BE WETH
  name: 'Wrapped MOVR',
  logo: movrLogo,
  description: 'ERC-20 Wrapped form of MOVR',
  swapableType: 'MOVR',
})

export const WAVAX = new Token({
  addresses: {
    [CHAIN_ID.AVALANCHE]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    [CHAIN_ID.MOONBEAM]: '0xA1f8890E39b4d8E33efe296D698fe42Fb5e59cC3',
  },
  decimals: 18,
  symbol: 'AVAX', // SHOULD BE WETH
  name: 'Wrapped AVAX',
  logo: avaxLogo,
  description: 'ERC-20 Wrapped form of AVAX',
  swapableType: 'AVAX',
})

// BASE TOKENS PART OF NEXT DEPLOY
// reusing 'AVAX' at 0xA1f8890E39b4d8E33efe296D698fe42Fb5e59cC3
// reusing 'MOVR' at 0x1d4C2a246311bB9f827F4C768e277FF5787B7D7E

export const BASIC_TOKENS_BY_CHAIN = {
  [CHAIN_ID.ETH]: [USDC, USDT, DAI, NUSD, SYN, WETH, ETH, HIGHSTREET, DOG, FRAX, GOHM],
  [CHAIN_ID.BSC]: [BUSD, USDC, USDT, NUSD, SYN, NFD, HIGHSTREET, DOG, JUMP, GOHM],
  [CHAIN_ID.POLYGON]: [USDC, USDT, DAI, NUSD, SYN, GOHM, DOG, NFD],
  [CHAIN_ID.FANTOM]: [MIM, USDC, USDT, NUSD, SYN, JUMP, GOHM],
  [CHAIN_ID.BOBA]: [SYN, NETH, WETH, ETH, USDC, USDT, DAI, NUSD, GOHM],
  [CHAIN_ID.MOONBEAM]: [
    // FRAX, #temp
    // WETHBEAM, #temp
    GOHM,
    SOLAR,
    WMOVR,
    WAVAX,
    SYN,
  ],
  [CHAIN_ID.MOONRIVER]: [SYN, FRAX, SOLAR, GOHM, MOVR, WMOVR],
  [CHAIN_ID.ARBITRUM]: [
    NETH,
    SYN,
    WETH,
    ETH,
    USDC,
    USDT,
    DAI,
    MIM,
    NUSD,
    GOHM,
    GMX,
    // DOG
  ],
  [CHAIN_ID.AVALANCHE]: [USDC, USDT, DAI, WETHE, NETH, NUSD, SYN, NFD, GOHM, GMX, AVAX, WAVAX],
  [CHAIN_ID.AURORA]: [USDC, USDT, NUSD, SYN],
  [CHAIN_ID.HARMONY]: [USDC, USDT, DAI, NUSD, FRAX, SYN, GOHM, ONEETH, NETH],
  [CHAIN_ID.OPTIMISM]: [NETH, SYN, WETH, ETH],
}

export const METAPOOL_TOKENS_BY_CHAIN = {
  [CHAIN_ID.BSC]: [NUSD],
  [CHAIN_ID.ETH]: [],
  [CHAIN_ID.POLYGON]: [NUSD],
  [CHAIN_ID.FANTOM]: [NUSD],
  [CHAIN_ID.BOBA]: [NUSD],
  [CHAIN_ID.MOONRIVER]: [],
  [CHAIN_ID.ARBITRUM]: [NUSD],
  [CHAIN_ID.AVALANCHE]: [NUSD],
  [CHAIN_ID.HARMONY]: [NUSD],
  [CHAIN_ID.AURORA]: [],
}

let TOKEN_HASH_MAP = {}

for (const [CHAIN_ID, tokensOnChain] of _.toPairs(BASIC_TOKENS_BY_CHAIN)) {
  TOKEN_HASH_MAP[CHAIN_ID] = {}
  for (const token of tokensOnChain) {
    TOKEN_HASH_MAP[CHAIN_ID][_.toLower(token.addresses[CHAIN_ID])] = token
  }
}

export { TOKEN_HASH_MAP }
