import arbitrumLogo from '../assets/icons/arbitrum.svg'
import auroraLogo from '../assets/icons/aurora.svg'
import avalancheLogo from '../assets/icons/avalanche.svg'
import binanceLogo from '../assets/icons/binance.svg'
import bobaLogo from '../assets/icons/boba.svg'
import ethLogo from '../assets/icons/eth.svg'
import fantomLogo from '../assets/icons/fantom.svg'
import harmonyLogo from '../assets/icons/harmonyone.svg'
import optimismLogo from '../assets/icons/optimism.svg'
import polygonLogo from '../assets/icons/polygon.svg'
import arbitrumImg from '../assets/networks/arbitrum.jpg'
import auroraImg from '../assets/networks/aurora.png'
import avalancheImg from '../assets/networks/avalanche.jpg'
import bobaImg from '../assets/networks/boba.png'
import bscImg from '../assets/networks/bsc.jpg'
import ethImg from '../assets/networks/eth.jpg'
import fantomImg from '../assets/networks/fantom.jpg'
import harmonyImg from '../assets/networks/harmonyone.jpg'
import moonbeamImg from '../assets/networks/moonbeam.jpg'
import moonriverImg from '../assets/networks/moonriver.jpeg'
import optimismImg from '../assets/networks/optimism.png'
import polygonImg from '../assets/networks/polygon.jpg'

export function toHexStr(num) {
  return `0x${num.toString(16)}`
}

export const CHAIN_ID = {
  ETH: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GÖRLI: 5,
  OPTIMISM: 10,
  KOVAN: 42,
  BSC: 56,
  POLYGON: 137,
  FANTOM: 250,
  BOBA: 288,
  MOONBEAM: 1284,
  MOONRIVER: 1285,
  HARDHAT: 31337,
  ARBITRUM: 42161,
  AVALANCHE: 43114,
  AURORA: 1313161554,
  HARMONY: 1666600000,
}

export const CHAIN_ID_REVERSE = Object.fromEntries(Object.entries(CHAIN_ID).map(([k, v]) => [v, k]))

export const CHAIN_ID_NAMES = {
  [CHAIN_ID.ETH]: 'ethereum',
  [CHAIN_ID.OPTIMISM]: 'optimism',
  [CHAIN_ID.BSC]: 'bsc',
  [CHAIN_ID.POLYGON]: 'polygon',
  [CHAIN_ID.FANTOM]: 'fantom',
  [CHAIN_ID.BOBA]: 'boba',
  [CHAIN_ID.MOONBEAM]: 'moonbeam',
  [CHAIN_ID.MOONRIVER]: 'moonriver',
  [CHAIN_ID.ARBITRUM]: 'arbitrum',
  [CHAIN_ID.AVALANCHE]: 'avalanche',
  [CHAIN_ID.AURORA]: 'aurora',
  [CHAIN_ID.HARMONY]: 'harmony',
}

export const CHAIN_ID_NAMES_REVERSE = Object.fromEntries(Object.entries(CHAIN_ID_NAMES).map(([k, v]) => [v, k]))

export const CHAIN_INFO = {
  [CHAIN_ID.ETH]: {
    CHAIN_ID: CHAIN_ID.ETH,
    chainSymbol: 'ETH',
    chainName: 'Ethereum',
    chainLogo: ethLogo,
    chainImg: ethImg,
    chainColor: '#28a0f0',
  },
  [CHAIN_ID.OPTIMISM]: {
    CHAIN_ID: CHAIN_ID.OPTIMISM,
    chainSymbol: 'OPTIMISM',
    chainName: 'Optimism',
    chainLogo: optimismLogo,
    chainImg: optimismImg,
    chainColor: '#fe0621',
  },
  [CHAIN_ID.BSC]: {
    CHAIN_ID: CHAIN_ID.BSC,
    chainSymbol: 'BSC',
    chainName: 'Binance Smart Chain',
    chainShortName: 'BSC',
    chainLogo: binanceLogo,
    chainImg: bscImg,
    chainColor: '#efb90b',
  },
  [CHAIN_ID.POLYGON]: {
    CHAIN_ID: CHAIN_ID.POLYGON,
    chainSymbol: 'POLYGON',
    chainName: 'Polygon',
    chainLogo: polygonLogo,
    chainImg: polygonImg,
    chainColor: '#7b3fe4',
  },
  [CHAIN_ID.FANTOM]: {
    CHAIN_ID: CHAIN_ID.FANTOM,
    chainSymbol: 'FANTOM',
    chainName: 'Fantom',
    chainLogo: fantomLogo,
    chainImg: fantomImg,
    chainColor: '#1969ff',
  },
  [CHAIN_ID.BOBA]: {
    CHAIN_ID: CHAIN_ID.BOBA,
    chainSymbol: 'BOBA',
    chainName: 'Boba Network',
    chainLogo: bobaLogo,
    chainImg: bobaImg,
    chainColor: '#cbff00',
  },
  [CHAIN_ID.MOONBEAM]: {
    CHAIN_ID: CHAIN_ID.MOONBEAM,
    chainSymbol: 'MOONBEAM',
    chainName: 'Moonbeam',
    chainLogo: moonbeamImg,
    chainImg: moonbeamImg,
    chainColor: '#f2b707',
  },
  [CHAIN_ID.MOONRIVER]: {
    CHAIN_ID: CHAIN_ID.MOONRIVER,
    chainSymbol: 'MOONRIVER',
    chainName: 'Moonriver',
    chainLogo: moonriverImg,
    chainImg: moonriverImg,
    chainColor: '#f2b707',
  },
  [CHAIN_ID.ARBITRUM]: {
    CHAIN_ID: CHAIN_ID.ARBITRUM,
    chainSymbol: 'ARBITRUM',
    chainName: 'Arbitrum',
    chainLogo: arbitrumLogo,
    chainImg: arbitrumImg,
    chainColor: '#434971',
  },
  [CHAIN_ID.AVALANCHE]: {
    CHAIN_ID: CHAIN_ID.AVALANCHE,
    chainSymbol: 'AVALANCHE',
    chainName: 'Avalanche',
    chainLogo: avalancheLogo,
    chainImg: avalancheImg,
    chainColor: '#e74242',
  },
  [CHAIN_ID.AURORA]: {
    CHAIN_ID: CHAIN_ID.AURORA,
    chainSymbol: 'AURORA',
    chainName: 'Aurora',
    chainLogo: auroraLogo,
    chainImg: auroraImg,
    chainColor: '#78d64b',
  },
  [CHAIN_ID.HARMONY]: {
    CHAIN_ID: CHAIN_ID.HARMONY,
    chainSymbol: 'HARMONY',
    chainName: 'Harmony',
    chainLogo: harmonyLogo,
    chainImg: harmonyImg,
    chainColor: '#39cdd8',
  },
}

export const SUPPORTED_CHAINS = Object.keys(CHAIN_INFO)

// main read rpcs, for write, it may be overridden
export const CHAIN_RPCS = {
  [CHAIN_ID.ETH]: 'https://eth-mainnet.alchemyapi.io/v2/2KGc5anzcDyuKCRT3EXPd7QanER_vvJq', // 'https://eth-mainnet.alchemyapi.io/v2/0AovFRYl9L7l4YUf6nPaMrs7H2_pj_Pf',
  [CHAIN_ID.OPTIMISM]: 'https://mainnet.optimism.io',
  [CHAIN_ID.BSC]: 'https://bsc-dataseed1.binance.org/', // this will prob backfire but hey 'https://bsc-dataseed.binance.org/',
  [CHAIN_ID.FANTOM]: 'https://rpc.ftm.tools/',
  [CHAIN_ID.BOBA]: 'https://replica-oolong.boba.network/',
  [CHAIN_ID.MOONBEAM]: 'https://rpc.api.moonbeam.network', // 'https://replica-boba-synapse.boba.network/',
  // [CHAIN_ID.MOONBEAM]: 'https://moonbeam.api.onfinality.io/public',
  [CHAIN_ID.MOONRIVER]: 'https://rpc.moonriver.moonbeam.network',
  // [CHAIN_ID.POLYGON]:   'https://polygon-mainnet.g.alchemy.com/v2/TXIDdlhxWM3VmgCcIkb8NXAuWdELAidn',
  [CHAIN_ID.POLYGON]: 'https://polygon-rpc.com/', // NEED TO TEST POLYGON PRIOR TO DEPLOY
  [CHAIN_ID.AVALANCHE]: 'https://api.avax.network/ext/bc/C/rpc',
  [CHAIN_ID.ARBITRUM]: 'https://arb1.arbitrum.io/rpc',
  [CHAIN_ID.AURORA]: 'https://mainnet.aurora.dev',
  [CHAIN_ID.HARMONY]: 'https://harmony-0-rpc.gateway.pokt.network/', // 'https://api.harmony.one',
  // [CHAIN_ID.XDAI]: 'https://rpc.xdaichain.com',
}

export const CHAIN_EXPLORERS = {
  [CHAIN_ID.BSC]: 'https://bscscan.com',
  [CHAIN_ID.ETH]: 'https://etherscan.com',
  [CHAIN_ID.POLYGON]: 'https://polygonscan.com',
  [CHAIN_ID.BOBA]: 'https://blockexplorer.boba.network',
  [CHAIN_ID.MOONBEAM]: 'https://blockscout.moonbeam.network/',
  [CHAIN_ID.MOONRIVER]: 'https://moonriver.moonscan.io',
  [CHAIN_ID.ARBITRUM]: 'https://arbiscan.io',
  [CHAIN_ID.OPTIMISM]: 'https://optimistic.etherscan.io',
  [CHAIN_ID.AVALANCHE]: 'https://snowtrace.io',
  [CHAIN_ID.FANTOM]: 'https://ftmscan.com',
  [CHAIN_ID.HARMONY]: 'https://explorer.harmony.one',
  [CHAIN_ID.AURORA]: 'https://explorer.mainnet.aurora.dev',
}

export const CHAIN_RPCS_WRITE = {
  ...CHAIN_RPCS,
  [CHAIN_ID.ETH]: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  [CHAIN_ID.BOBA]: 'https://mainnet.boba.network/',
}

export const ETH_NATIVE_CURRENCY = {
  name: 'Ethereum',
  symbol: 'ETH',
  decimals: 18,
}

/**
 * The below need to be MetaMask compatible keys/objects.
 * extra keys can cause MetaMask to cause really unexpected errors
 */
export const CHAIN_PARAMS = {
  [CHAIN_ID.ETH]: {
    CHAIN_ID: toHexStr(CHAIN_ID.ETH),
    chainName: CHAIN_INFO[CHAIN_ID.ETH].chainName,
    nativeCurrency: ETH_NATIVE_CURRENCY,
    rpcUrls: [CHAIN_RPCS_WRITE[CHAIN_ID.ETH]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.ETH]],
  },
  [CHAIN_ID.OPTIMISM]: {
    CHAIN_ID: toHexStr(CHAIN_ID.OPTIMISM),
    chainName: CHAIN_INFO[CHAIN_ID.OPTIMISM].chainName,
    nativeCurrency: ETH_NATIVE_CURRENCY,
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.OPTIMISM]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.OPTIMISM]],
  },
  [CHAIN_ID.BSC]: {
    CHAIN_ID: toHexStr(CHAIN_ID.BSC),
    chainName: CHAIN_INFO[CHAIN_ID.BSC].chainName,
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.BSC]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.BSC]],
  },
  [CHAIN_ID.POLYGON]: {
    CHAIN_ID: toHexStr(CHAIN_ID.POLYGON),
    chainName: CHAIN_INFO[CHAIN_ID.POLYGON].chainName,
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.POLYGON]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.POLYGON]],
  },
  [CHAIN_ID.FANTOM]: {
    CHAIN_ID: toHexStr(CHAIN_ID.FANTOM),
    chainName: CHAIN_INFO[CHAIN_ID.FANTOM].chainName,
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.FANTOM]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.FANTOM]],
  },
  [CHAIN_ID.BOBA]: {
    CHAIN_ID: toHexStr(CHAIN_ID.BOBA),
    chainName: CHAIN_INFO[CHAIN_ID.BOBA].chainName,
    nativeCurrency: ETH_NATIVE_CURRENCY,
    rpcUrls: [CHAIN_RPCS_WRITE[CHAIN_ID.BOBA]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.BOBA]],
  },
  [CHAIN_ID.MOONBEAM]: {
    CHAIN_ID: toHexStr(CHAIN_ID.MOONBEAM),
    chainName: CHAIN_INFO[CHAIN_ID.MOONBEAM].chainName,
    nativeCurrency: {
      name: 'Glimmer',
      symbol: 'GLMR',
      decimals: 18,
    },
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.MOONBEAM]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.MOONBEAM]],
  },
  [CHAIN_ID.MOONRIVER]: {
    CHAIN_ID: toHexStr(CHAIN_ID.MOONRIVER),
    chainName: CHAIN_INFO[CHAIN_ID.MOONRIVER].chainName,
    nativeCurrency: {
      name: 'Moonriver',
      symbol: 'MOVR',
      decimals: 18,
    },
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.MOONRIVER]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.MOONRIVER]],
  },
  [CHAIN_ID.ARBITRUM]: {
    CHAIN_ID: toHexStr(CHAIN_ID.ARBITRUM),
    chainName: CHAIN_INFO[CHAIN_ID.ARBITRUM].chainName,
    nativeCurrency: ETH_NATIVE_CURRENCY,
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.ARBITRUM]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.ARBITRUM]],
  },
  [CHAIN_ID.AVALANCHE]: {
    CHAIN_ID: toHexStr(CHAIN_ID.AVALANCHE),
    chainName: CHAIN_INFO[CHAIN_ID.AVALANCHE].chainName,
    nativeCurrency: {
      name: 'Avax',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.AVALANCHE]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.AVALANCHE]],
  },
  [CHAIN_ID.AURORA]: {
    CHAIN_ID: toHexStr(CHAIN_ID.AURORA),
    chainName: CHAIN_INFO[CHAIN_ID.AURORA].chainName,
    nativeCurrency: ETH_NATIVE_CURRENCY,
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.AURORA]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.AURORA]],
  },
  [CHAIN_ID.HARMONY]: {
    CHAIN_ID: toHexStr(CHAIN_ID.HARMONY),
    chainName: CHAIN_INFO[CHAIN_ID.HARMONY].chainName,
    nativeCurrency: {
      name: 'Harmony One',
      symbol: 'ONE',
      decimals: 18,
    },
    rpcUrls: [CHAIN_RPCS[CHAIN_ID.HARMONY]],
    blockExplorerUrls: [CHAIN_EXPLORERS[CHAIN_ID.HARMONY]],
  },
}
